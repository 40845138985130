@tailwind base;
@tailwind components;
@tailwind utilities;

* a {
  text-decoration: none;
}

body {
  padding-top: 54px;
}

@media (min-width: 600px) {
  body {
    padding-top: 64px;
  }
}

.slick-dots li {
  display: flex !important;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.slick-dots li.slick-active {
  background-color: rgb(25, 118, 210);
  color: white;
}

.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}
